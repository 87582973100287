"use strict";

class printReportController {
  constructor(
    Auth,
    $state,
    $q,
    usSpinnerService,
    $timeout,
    $stateParams,
    Report,
    Finding,
    Repair,
    Weather,
    appConfig,
    Util,
    $scope
  ) {
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$q = $q;
    this.Report = Report;
    this.appConfig = appConfig;
    this.API_KEY = appConfig.GOOGLE_API_KEY;
    this.Util = Util;
    this.getAddress = Util.getAddress;
    this.getLatLng = Util.getLatLng;
    this.mapImageName = "";
    this.turbineRisk = "";
    this.turbineDefectRecommendation = "";
    this.Finding = Finding;
    this.Repair = Repair;
    this.Weather = Weather;
    this.allFindings = [];
    this.findings = [];
    this.bladePositionMap = {
      A: 1,
      B: 2,
      C: 3,
    };
    this.disableNavigation = $scope.disableNavigation;
    this.getFindingCategoryText = Util.getFindingCategoryText;
    this.getDefectRecommendation = Util.getDefectRecommendation;
    this.highestCategoryForSite = -1;
    this.init();
    this.graphWidth = 212;
    this.graphHeight = 250;
    document.body.addEventListener(
      "load",
      function (event) {
        var tgt = event.target;
        if (tgt.tagName == "IMG") {
          tgt.onload = function () {
            var imgClass = "";
            if (tgt.width <= tgt.height) {
              imgClass = "portrait";
              // console.log(imgClass);
              tgt.classList.add("portrait");
            } else {
              imgClass = "landscape";
              // console.log(imgClass);
              tgt.classList.add("landscape");
            }
          };
        }
      },
      true // <-- useCapture
    );
  }

  init() {
    this.Report.get({ id: this.$stateParams.id })
      .$promise.then((response) => {
        if (response.data) {
          this.reportData = response.data;
          console.log("reportData: ", this.reportData);
          // console.log('bladeData: ', this.reportData.blades);

          // if (this.reportData && this.reportData.findingsCount.cat1 >= 0
          // 	&& this.reportData.findingsCount.cat2 >= 0 && this.reportData.findingsCount.cat3 >= 0
          // 	&& this.reportData.findingsCount.cat4 >= 0 && this.reportData.findingsCount.cat5 >= 0) {

          // 	this.reportData.totalFindingsCount = this.reportData.findingsCount.cat1 +
          // 		this.reportData.findingsCount.cat2 + this.reportData.findingsCount.cat3 +
          // 		this.reportData.findingsCount.cat4 + this.reportData.findingsCount.cat5;
          // }

          this.groupedTurbines = _.groupBy(
            this.reportData.blades,
            "turbine._id"
          );
          // console.log(this.groupedTurbines);
          let turbines = [];
          _.each(this.groupedTurbines, function (val, key) {
            // console.log(val);
            let turbine = {};
            turbine = val[0].turbine;
            turbine.blades = val;
            turbines.push(turbine);
          });
          this.turbines = turbines;
          // console.log('turbines: ', this.turbines);

          this.turbineData = this.turbines[0];
          this.turbinesInUI = this.turbines;
          let overviewManufacture = [];
          let overviewModel = [];
          this.turbines.forEach((turbine) => {
            if (turbine.make) {
              overviewManufacture.push(turbine.make);
            }
            if (turbine.model) {
              overviewModel.push(turbine.model);
            }
          });

          this.siteOverview = {
            reportNumber:
              this.reportData.reportNumber || Math.round(Date.now() / 1000),
            siteName: this.reportData.site.name,
            latitude:
              this.reportData.site.location &&
              this.reportData.site.location.position &&
              this.reportData.site.location.position[0],
            longitude:
              this.reportData.site.location &&
              this.reportData.site.location.position &&
              this.reportData.site.location.position[1],
            siteLocation: this.Util.getAddressForReport(
              this.reportData.site.location
            ),
            // sitePosition: this.turbineData.sitePosition,
            // turbineSerialNo: this.turbineData.serial,
            // turbineBlades: this.turbineData.blades,
            turbineMake: overviewManufacture.join(", "),
            turbineModel: overviewModel.join(", "),
            // completionDate: new Date(this.finding.date || this.finding.createdAt),
            reportDate: new Date(),
          };
          // console.log(this.siteOverview);

          // var finding = this.reportData.findingsCount.turbineCategory[this.turbineData._id];
          // if (finding) {
          // 	const findingClasses = this.Util.getFindingClass(finding);
          // 	this.turbineRisk = this.Util.getFindingCategoryText(finding.category);
          // 	this.turbineDefectRecommendation = this.Util.getDefectRecommendation(finding.category);

          // 	this.mapImageName = _.findKey(findingClasses, function (value, key) {
          // 		return findingClasses[key] === true;
          // 	})
          // }
          // let findingDates = [];
          this.sitePositionArr = [];
          this.turbinesInUI.forEach((turbine) => {
            this.sitePositionArr.push(turbine.sitePositionV2);
            var finding =
              this.reportData.findingsCount.turbineCategory[turbine._id];
            if (finding) {
              const findingClasses = this.Util.getFindingClass(finding);
              turbine.turbineRisk = this.Util.getFindingCategoryText(
                finding.category
              );
              turbine.turbineDefectRecommendation =
                this.Util.getDefectRecommendation(finding.category);

              turbine.riskIndicator = _.findKey(
                findingClasses,
                function (value, key) {
                  return findingClasses[key] === true;
                }
              );

              if (finding.category > this.highestCategoryForSite) {
                this.mapImageName = _.findKey(
                  findingClasses,
                  function (value, key) {
                    return findingClasses[key] === true;
                  }
                );
                this.highestCategoryForSite = finding.category;
              }
            }

            this.Weather.getForTurbineFindings(
              { id: "get-for-blade-findings" },
              {
                siteId: this.reportData.site._id,
                bladeIds: _.map(turbine.blades, (blade) => blade._id),
              }
            )
              .$promise.then((response) => {
                turbine.weatherData = response.data;
              })
              .catch((err) => {
                turbine.weatherData = [];
                console.log(err);
              });

            let totalTurbineFindings = 0;
            let turbineFindingCat0 = 0;
            let turbineFindingCat1 = 0;
            let turbineFindingCat2 = 0;
            let turbineFindingCat3 = 0;
            let turbineFindingCat4 = 0;
            let turbineFindingCat5 = 0;
            let qry = {
              id: turbine._id,
              startDate: this.reportData.startDate,
              endDate: this.reportData.endDate,
            };
            // console.log('Turbine Id: ', turbine._id);
            if (this.reportData.type == "finding") {
              this.Finding.get(qry)
                .$promise.then((response) => {
                  // turbine.findings = response.data;
                  turbine.components = printReportController.getBladesGroup(
                    response.data.slice(),
                    this.reportData.type
                  );
                  turbine.totalRepairCount = _.sumBy(
                    turbine.components,
                    (component) => component.totalRepairCount
                  );
                  console.log(
                    "turbine.totalRepairCount",
                    turbine.totalRepairCount
                  );

                  turbine.components = _.filter(
                    turbine.components,
                    (component) => {
                      return !!_.find(turbine.blades, {
                        _id: component.blade._id,
                      });
                    }
                  );

                  // console.log('Turbine Baldes', turbine.blades);
                  // console.log('Turbine components', turbine.components);
                  turbine.components.forEach((component) => {
                    // console.log('Length: ',component.findings.length);
                    // console.log('this.reportData.findings:', this.reportData.findings);
                    // console.log('before component.findings:', component.findings);

                    component.findings = _.filter(
                      component.findings,
                      (finding) => {
                        return this.reportData.findings.includes(finding._id);
                      }
                    );

                    // console.log('after component.findings:', component.findings);

                    component.findings = _.filter(
                      component.findings,
                      (finding) =>
                        finding.category >= 0 && finding.category <= 5
                    );

                    component.findings.forEach((finding) => {
                      // console.log(finding.date);
                      if (!this.completionDate) {
                        this.completionDate = finding.date;
                      } else if (
                        this.reportData.type == "finding" &&
                        this.completionDate &&
                        new Date(finding.date).getTime() >
                          new Date(this.completionDate).getTime()
                      ) {
                        this.completionDate = finding.date;
                      }
                      if (
                        this.reportData.type == "finding" &&
                        !turbine.maxFindingDateForTurbine
                      ) {
                        turbine.maxFindingDateForTurbine = finding.date;
                      } else if (
                        this.reportData.type == "finding" &&
                        turbine.maxFindingDateForTurbine &&
                        new Date(finding.date).getTime() >
                          new Date(turbine.maxFindingDateForTurbine).getTime()
                      ) {
                        turbine.maxFindingDateForTurbine = finding.date;
                      }
                      // if(this.reportData.type == 'repair') {
                      // 	let repairByMaxDate = _.maxBy(finding.repairs, repair => new Date(repair.date || repair.createdAt).getTime());
                      // 	if(repairByMaxDate) {
                      // 		turbine.maxRepairDateForTurbine = repairByMaxDate.date || repairByMaxDate.createdAt;
                      // 		if(new Date(turbine.maxRepairDateForTurbine) > new Date(this.completionDate).getTime()) {
                      // 			this.completionDate = turbine.maxRepairDateForTurbine;
                      // 		}
                      // 	}
                      // }
                    });

                    component.graphData = [
                      {
                        key: "Cat 0 : No Risk",
                        y: component.findings.filter(
                          (finding) => finding.category == 0
                        ).length,
                        color: this.appConfig.colors.cat0,
                      },
                      {
                        key: "Cat 1 : Low",
                        y: component.findings.filter(
                          (finding) => finding.category == 1
                        ).length,
                        color: this.appConfig.colors.cat1,
                      },
                      {
                        key: "Cat 2 : Medium",
                        y: component.findings.filter(
                          (finding) => finding.category == 2
                        ).length,
                        color: this.appConfig.colors.cat2,
                      },
                      {
                        key: "Cat 3 : Serious",
                        y: component.findings.filter(
                          (finding) => finding.category == 3
                        ).length,
                        color: this.appConfig.colors.cat3,
                      },
                      {
                        key: "Cat 4 : Major",
                        y: component.findings.filter(
                          (finding) => finding.category == 4
                        ).length,
                        color: this.appConfig.colors.cat4,
                      },
                      {
                        key: "Cat 5 : Critical",
                        y: component.findings.filter(
                          (finding) => finding.category == 5
                        ).length,
                        color: this.appConfig.colors.cat5,
                      },
                    ];

                    turbineFindingCat0 =
                      turbineFindingCat0 +
                      component.findings.filter(
                        (finding) => finding.category == 0
                      ).length;

                    turbineFindingCat1 =
                      turbineFindingCat1 +
                      component.findings.filter(
                        (finding) => finding.category == 1
                      ).length;

                    turbineFindingCat2 =
                      turbineFindingCat2 +
                      component.findings.filter(
                        (finding) => finding.category == 2
                      ).length;

                    turbineFindingCat3 =
                      turbineFindingCat3 +
                      component.findings.filter(
                        (finding) => finding.category == 3
                      ).length;

                    turbineFindingCat4 =
                      turbineFindingCat4 +
                      component.findings.filter(
                        (finding) => finding.category == 4
                      ).length;

                    turbineFindingCat5 =
                      turbineFindingCat5 +
                      component.findings.filter(
                        (finding) => finding.category == 5
                      ).length;

                    var max = -Infinity,
                      key;
                    let totalFinding = 0;
                    component.graphData.forEach(function (v, k) {
                      if (max < +v.y) {
                        max = +v.y;
                        key = k;
                      }
                      totalFinding = totalFinding + v.y;
                    });
                    component.totalFindingsCount = totalFinding;
                    totalTurbineFindings = totalTurbineFindings + totalFinding;
                    // console.log(totalFinding);
                    /* Chart options */
                    component.options = {
                      chart: {
                        type: "pieChart",
                        width: this.graphWidth,
                        height: this.graphHeight,
                        donut: true,
                        title: component.totalFindingsCount,
                        x: function (d) {
                          return d.key;
                        },
                        y: function (d) {
                          return d.y;
                        },
                        showLegend: true,
                        showLabels: true,
                        showValues: true,
                        valueFormat: function (d) {
                          return d3.format(",.0f")(d);
                        },
                        labelType: function (d) {
                          return d.value;
                        },
                        color: function (d, i) {
                          return d && d.color;
                        },
                        duration: 500,
                      },
                    };

                    component.graphData.heightestvalue = key;
                    // console.log(key);

                    turbine.components = _.sortBy(
                      turbine.components,
                      (component) => {
                        return this.bladePositionMap[component.blade.position]
                          ? this.bladePositionMap[component.blade.position]
                          : component.blade.position;
                      }
                    );
                    // console.log('components: ', this.components);
                  });
                  turbine.totalTurbineFindings = totalTurbineFindings;

                  turbine.graphData = [
                    {
                      key: "Cat 0 : No Risk",
                      y: turbineFindingCat0,
                      color: this.appConfig.colors.cat0,
                    },
                    {
                      key: "Cat 1 : Low",
                      y: turbineFindingCat1,
                      color: this.appConfig.colors.cat1,
                    },
                    {
                      key: "Cat 2 : Medium",
                      y: turbineFindingCat2,
                      color: this.appConfig.colors.cat2,
                    },
                    {
                      key: "Cat 3 : Serious",
                      y: turbineFindingCat3,
                      color: this.appConfig.colors.cat3,
                    },
                    {
                      key: "Cat 4 : Major",
                      y: turbineFindingCat4,
                      color: this.appConfig.colors.cat4,
                    },
                    {
                      key: "Cat 5 : Critical",
                      y: turbineFindingCat5,
                      color: this.appConfig.colors.cat5,
                    },
                  ];

                  /* Chart options */
                  turbine.options = {
                    chart: {
                      type: "pieChart",
                      width: this.graphWidth,
                      height: this.graphHeight,
                      donut: true,
                      title: totalTurbineFindings,
                      x: function (d) {
                        return d.key;
                      },
                      y: function (d) {
                        return d.y;
                      },
                      showLegend: true,
                      showLabels: true,
                      showValues: true,
                      valueFormat: function (d) {
                        return d3.format(",.0f")(d);
                      },
                      labelType: function (d) {
                        return d.value;
                      },
                      color: function (d, i) {
                        return d && d.color;
                      },
                      duration: 500,
                    },
                  };
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              this.Repair.get(qry)
                .$promise.then((response) => {
                  this.Finding.get(qry)
                    .$promise.then((findingResponse) => {
                      let findingsGroupByBlades =
                        printReportController.getBladesGroup(
                          findingResponse.data.slice(),
                          "finding"
                        );
                      turbine.components = printReportController.getBladesGroup(
                        response.data.slice(),
                        this.reportData.type
                      );
                      // turbine.totalRepairCount = _.sumBy(turbine.components, component=> component.totalRepairCount);
                      // console.log('turbine.totalRepairCount', turbine.totalRepairCount);

                      turbine.components = _.filter(
                        turbine.components,
                        (component) => {
                          return !!_.find(turbine.blades, {
                            _id: component.blade._id,
                          });
                        }
                      );
                      let totalRepair = 0;
                      turbine.components.forEach((component) => {
                        // component.findings = _.filter(component.findings, finding => finding.category >= 0 && finding.category <=5);
                        let componentWithFindings = _.find(
                          findingsGroupByBlades,
                          (findingComponent) =>
                            findingComponent.blade._id == component.blade._id
                        );

                        component.repairs = _.filter(
                          component.repairs,
                          (repair) => {
                            return this.reportData.repairs.includes(repair._id);
                          }
                        );
                        // console.log('component.repairs.length:', component.repairs.length);
                        totalRepair = totalRepair + component.repairs.length;
                        component.repairs.forEach((repair) => {
                          // console.log(repair.date);

                          const overviewImages = [];
                          if (repair.images && repair.images.length > 0) {
                            overviewImages.push(repair.images[0]);
                          }
                          if (
                            repair.closeUpImages &&
                            repair.closeUpImages.length > 0
                          ) {
                            overviewImages.push(repair.closeUpImages[0]);
                          }
                          if (
                            repair.defectProcedure &&
                            repair.defectProcedure.conditions
                          ) {
                            repair.defectProcedure.conditions.forEach(
                              (condition) => {
                                if (condition.images.length > 0) {
                                  overviewImages.push(condition.images[0]);
                                }
                              }
                            );
                          }
                          repair.overviewImages = overviewImages;
                          repair.overviewImages = _.filter(
                            repair.overviewImages,
                            (overviewImage) => overviewImage.path
                          );

                          if (!this.completionDate) {
                            this.completionDate =
                              repair.date || repair.createdAt;
                          } else if (
                            this.completionDate &&
                            new Date(repair.date).getTime() >
                              new Date(this.completionDate).getTime()
                          ) {
                            this.completionDate =
                              repair.date || repair.createdAt;
                          }
                          if (!turbine.maxRepairDateForTurbine) {
                            turbine.maxRepairDateForTurbine =
                              repair.date || repair.createdAt;
                          } else if (
                            turbine.maxRepairDateForTurbine &&
                            new Date(repair.date).getTime() >
                              new Date(
                                turbine.maxRepairDateForTurbine
                              ).getTime()
                          ) {
                            turbine.maxRepairDateForTurbine =
                              repair.date || repair.createdAt;
                          }
                        });

                        component.graphData = [
                          {
                            key: "Cat 0 : No Risk",
                            y:
                              componentWithFindings &&
                              componentWithFindings.findings
                                ? componentWithFindings.findings.filter(
                                    (finding) => finding.category == 0
                                  ).length
                                : 0,
                            color: this.appConfig.colors.cat0,
                          },
                          {
                            key: "Cat 1 : Low",
                            y:
                              componentWithFindings &&
                              componentWithFindings.findings
                                ? componentWithFindings.findings.filter(
                                    (finding) => finding.category == 1
                                  ).length
                                : 0,
                            color: this.appConfig.colors.cat1,
                          },
                          {
                            key: "Cat 2 : Medium",
                            y:
                              componentWithFindings &&
                              componentWithFindings.findings
                                ? componentWithFindings.findings.filter(
                                    (finding) => finding.category == 2
                                  ).length
                                : 0,
                            color: this.appConfig.colors.cat2,
                          },
                          {
                            key: "Cat 3 : Serious",
                            y:
                              componentWithFindings &&
                              componentWithFindings.findings
                                ? componentWithFindings.findings.filter(
                                    (finding) => finding.category == 3
                                  ).length
                                : 0,
                            color: this.appConfig.colors.cat3,
                          },
                          {
                            key: "Cat 4 : Major",
                            y:
                              componentWithFindings &&
                              componentWithFindings.findings
                                ? componentWithFindings.findings.filter(
                                    (finding) => finding.category == 4
                                  ).length
                                : 0,
                            color: this.appConfig.colors.cat4,
                          },
                          {
                            key: "Cat 5 : Critical",
                            y:
                              componentWithFindings &&
                              componentWithFindings.findings
                                ? componentWithFindings.findings.filter(
                                    (finding) => finding.category == 5
                                  ).length
                                : 0,
                            color: this.appConfig.colors.cat5,
                          },
                        ];

                        // turbineFindingCat1 = turbineFindingCat1 + componentWithFindings.findings.filter(finding => finding.category == 1).length;

                        // turbineFindingCat2 = turbineFindingCat2 + componentWithFindings.findings.filter(finding => finding.category == 2).length;

                        // turbineFindingCat3 = turbineFindingCat3 + componentWithFindings.findings.filter(finding => finding.category == 3).length;

                        // turbineFindingCat4 = turbineFindingCat4 + componentWithFindings.findings.filter(finding => finding.category == 4).length;

                        // turbineFindingCat5 = turbineFindingCat5 + componentWithFindings.findings.filter(finding => finding.category == 5).length;

                        var max = -Infinity,
                          key;
                        let totalFinding = 0;
                        component.graphData.forEach(function (v, k) {
                          if (max < +v.y) {
                            max = +v.y;
                            key = k;
                          }
                          totalFinding = totalFinding + v.y;
                        });

                        component.totalFindingsCount = totalFinding;
                        // totalTurbineFindings = totalTurbineFindings + totalFinding;
                        // console.log(totalFinding);
                        /* Chart options */
                        component.options = {
                          chart: {
                            type: "pieChart",
                            width: this.graphWidth,
                            height: this.graphHeight,
                            donut: true,
                            title: totalFinding,
                            x: function (d) {
                              return d.key;
                            },
                            y: function (d) {
                              return d.y;
                            },
                            showLegend: true,
                            showLabels: true,
                            showValues: true,
                            valueFormat: function (d) {
                              return d3.format(",.0f")(d);
                            },
                            labelType: function (d) {
                              return d.value;
                            },
                            color: function (d, i) {
                              return d && d.color;
                            },
                            duration: 500,
                          },
                        };

                        component.graphData.heightestvalue = key;
                        // console.log(key);

                        turbine.components = _.sortBy(
                          turbine.components,
                          (component) => {
                            return this.bladePositionMap[
                              component.blade.position
                            ]
                              ? this.bladePositionMap[component.blade.position]
                              : component.blade.position;
                          }
                        );
                        // console.log('components: ', this.components);
                      });
                      turbine.totalRepairCount = totalRepair;
                      console.log(
                        "turbine.totalRepairCount: ",
                        turbine.totalRepairCount
                      );

                      _.each(findingsGroupByBlades, (findingComponent) => {
                        turbineFindingCat0 =
                          turbineFindingCat0 +
                          findingComponent.findings.filter(
                            (finding) => finding.category == 0
                          ).length;
                        turbineFindingCat1 =
                          turbineFindingCat1 +
                          findingComponent.findings.filter(
                            (finding) => finding.category == 1
                          ).length;
                        turbineFindingCat2 =
                          turbineFindingCat2 +
                          findingComponent.findings.filter(
                            (finding) => finding.category == 2
                          ).length;
                        turbineFindingCat3 =
                          turbineFindingCat3 +
                          findingComponent.findings.filter(
                            (finding) => finding.category == 3
                          ).length;
                        turbineFindingCat4 =
                          turbineFindingCat4 +
                          findingComponent.findings.filter(
                            (finding) => finding.category == 4
                          ).length;
                        turbineFindingCat5 =
                          turbineFindingCat5 +
                          findingComponent.findings.filter(
                            (finding) => finding.category == 5
                          ).length;

                        // totalTurbineFindings = totalTurbineFindings + turbineFindingCat1 + turbineFindingCat2 + turbineFindingCat3
                        // 						+ turbineFindingCat4 + turbineFindingCat5;
                      });
                      turbine.totalTurbineFindings =
                        findingResponse.data.length;

                      turbine.graphData = [
                        {
                          key: "Cat 0 : No Risk",
                          y: turbineFindingCat0,
                          color: this.appConfig.colors.cat0,
                        },
                        {
                          key: "Cat 1 : Low",
                          y: turbineFindingCat1,
                          color: this.appConfig.colors.cat1,
                        },
                        {
                          key: "Cat 2 : Medium",
                          y: turbineFindingCat2,
                          color: this.appConfig.colors.cat2,
                        },
                        {
                          key: "Cat 3 : Serious",
                          y: turbineFindingCat3,
                          color: this.appConfig.colors.cat3,
                        },
                        {
                          key: "Cat 4 : Major",
                          y: turbineFindingCat4,
                          color: this.appConfig.colors.cat4,
                        },
                        {
                          key: "Cat 5 : Critical",
                          y: turbineFindingCat5,
                          color: this.appConfig.colors.cat5,
                        },
                      ];

                      /* Chart options */
                      turbine.options = {
                        chart: {
                          type: "pieChart",
                          width: this.graphWidth,
                          height: this.graphHeight,
                          donut: true,
                          title:
                            turbineFindingCat0 +
                            turbineFindingCat1 +
                            turbineFindingCat2 +
                            turbineFindingCat3 +
                            turbineFindingCat4 +
                            turbineFindingCat5,
                          x: function (d) {
                            return d.key;
                          },
                          y: function (d) {
                            return d.y;
                          },
                          showLegend: true,
                          showLabels: true,
                          showValues: true,
                          valueFormat: function (d) {
                            return d3.format(",.0f")(d);
                          },
                          labelType: function (d) {
                            return d.value;
                          },
                          color: function (d, i) {
                            return d && d.color;
                          },
                          duration: 500,
                        },
                      };
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
          console.log("Turbine Data: ", this.turbinesInUI);
          // console.log('Finding Dates: ',findingDates);
        }
      })
      .catch((err) => {
        console.log(err);
        this.reportData = null;
      });
  }

  static getBladesGroup(allItems, type = "finding") {
    // if(type == 'repair') {
    // allItems = _.filter(allItems, item => item && item.repairs && item.repairs.length >0 );
    // _.each(allItems, item => {
    // 	_.each(item.repairs, repair => {
    // 		repair.closeUpImages = _.filter(repair.closeUpImages, closeUpImage => closeUpImage.path);
    // 		repair.images = _.filter(repair.images, image => image.path);
    // 	})
    // })
    // }
    const items = allItems.sort((a, b) => {
      if (!a.blade || !b.blade) {
        console.log("sort error no blade fount: ", a, b);
        return 0;
      }
      return a.blade.position - b.blade.position;
    });
    let allBlades = [];
    let blades = {};
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      if (item.blade && blades[item.blade._id] === undefined) {
        blades[item.blade._id] = [];
        blades[item.blade._id].totalRepairCount = 0;
      }
      if (item.blade) {
        item.type = type;
        blades[item.blade._id].push(item);
        blades[item.blade._id].totalRepairCount += 1;
      }
    }
    for (let key in blades) {
      let blade = {
        blade: blades[key][0].blade,
        totalRepairCount: blades[key].totalRepairCount,
      };
      if (type == "repair") {
        blade.repairs = blades[key];
      } else {
        blade.findings = blades[key];
      }
      allBlades.push(blade);
    }
    return allBlades;
  }

  getBladePosition(position) {
    return this.bladePositionMap[position]
      ? this.bladePositionMap[position]
      : position;
  }

  getBladeName(blade) {
    if (blade.name) {
      return blade.name;
    }
    if (blade.position.toLowerCase() === "a" || blade.position === 1) {
      return "Blade A/1";
    }
    if (blade.position.toLowerCase() === "b" || blade.position === 2) {
      return "Blade B/2";
    }
    if (blade.position.toLowerCase() === "c" || blade.position === 3) {
      return "Blade C/3";
    }
  }

  getClass(finding) {
    // console.log('ComponentMapCtrl', finding);
    if (finding) return this.Util.getFindingClass(finding);
  }

  getCavityInspectionTypeId(type) {
    return this.Util.getCavityInspectionTypeId(type);
  }
  getBladeIndex(position) {
    if (position == "A") {
      return 1;
    } else if (position == "B") {
      return "2";
    } else if (position == "C") {
      return "3";
    }
  }
}

angular
  .module("windmanagerApp")
  .controller("printReportController", printReportController);
